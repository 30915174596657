import SecondaryNavbar from "../../components/SecondaryNavbar";
import { LanguageContext } from "../../LanguageContext";
import React, { useContext } from "react";
import "../ProjectPage/projectpage.css";
import YouTube from "react-youtube";

export default function Waste() {
  const { language } = useContext(LanguageContext);
  const texts = {
    ita: {
      description:
        "Anche questo è un progetto d’esame, scritto e diretto dalla mitica Polina Rubis. In questo caso mi sono occupato del suono. L’avevo già fatto un paio di volte ma in questo caso ho davvero voluto essere professionale e ho dato il 100% per mettere Polina e Lucìa in condizione di editare il film nel migliore dei modi. Prendere il suono è particolare, è una grande responsabilità ma talvolta ci si sente fuori dalla crew. Ma a me non dispiace, lo trovo stimolante e può essere un’attività davvero molto tecnica. Credo di aver fatto un buon lavoro e mi sono divertito.",
      director: "Scrittore e Regista: ",
      casting: "Cast: ",
      photo: "Direttore della fotografia: ",
      montage: "Montaggio: ",
      dirAssist: "Assistente alla regia: ",
      productor: "Produttore: ",
      camAssist: "Assistente camera: ",
      dirLuci: "Capo elettricista: ",
      assistLuci: "Assistenti elettricista: ",
      script: "Segretaria d’edizione: ",
      tecSuono: "Tecnico del suono: ",
      runner: "Runner: ",
      place: "Svezia",
      role: "Tecnico del suono",
    },
    eng: {
      description:
        "This was an exam project, written and directed by the legendary Polina Rubis. In this case I took care of the sound. I had already done it a couple of times but in this case I really wanted to be professional and I gave 100% to put Polina and Lucìa in a position to edit the film in the best way. Taking the sound is particular, it is a great responsibility but sometimes you feel outside the crew. But I don’t mind, I find it stimulating and it can be a very technical activity. I think I did a good job and I enjoyed it.",
      director: "Writer and Director: ",
      casting: "Cast: ",
      photo: "Cinematographer: ",
      montage: "Editing: ",
      dirAssist: "Assistant director: ",
      productor: "Productor: ",
      camAssist: "Camera assistant: ",
      assistLuci: "Best boy: ",
      dirLuci: "Gaffer: ",
      script: "Script supervisor: ",
      tecSuono: "Sound operator: ",
      runner: "Runner: ",
      place: "Sweden",
      role: "Sound operator",
    },
    fra: {
      description:
        "Il s’agit également d’un projet d’examen, écrit et dirigé par la mythique Polina Rubis. Dans ce cas, je me suis occupé du son. Je l’avais déjà fait plusieurs fois mais dans ce cas, j’ai vraiment voulu être professionnel et j’ai donné 100% pour mettre Polina et Lucia en mesure de monter le film de la meilleure façon possible. Prendre le son est particulier, c’est une grande responsabilité mais parfois on se sent en dehors de l’équipe. Mais ça ne me dérange pas, je trouve cela stimulant et il peut être une activité très technique. Je pense que j’ai fait du bon boulot et je me suis bien amusé.",
      director: "Scénariste et réalisatrice ",
      casting: "Casting: ",
      photo: "Directeur de la photographie: ",
      montage: "Montage: ",
      dirAssist: "Assistant réalisateur: ",
      productor: "Producteur: ",
      camAssist: "Assistant caméra: ",
      dirLuci: "Chef électricien: ",
      assistLuci: "Assistants électriciens: ",
      script: "Scripte: ",
      tecSuono: "Opérateur son et conducteur: ",
      runner: "Runner: ",
      place: "Suède",
      role: "Opérateur son",
    },
  };
  return (
    <div className="project-page-wrapper">
      <SecondaryNavbar />
      <div className="project-page-video-left">
        <YouTube
          videoId="fSAGqXlh1Zo" // Replace with the YouTube video ID you want to display
          className="project-page-video" // Add a class name for styling if needed
        />
        <p className="project-video-description">
          {texts[language].description}
        </p>
      </div>
      <div className="project-page-right">
        <div className="right-items">
          <p className="upper-item">WASTE</p>
          <p className="upper-item">2023</p>
          <p className="upper-item">{texts[language].role}</p>
          <p className="upper-item">{texts[language].place}</p>
          <p className="upper-item">Stockholm Filmskola</p>
        </div>
        <p className="half-list"></p>
        <div className="left-items">
          <p className="lower-item">
            <strong>{texts[language].director}</strong> Polina Rubis
          </p>
          <p className="lower-item">
            <strong>{texts[language].casting}</strong> Max Foroozesh, Richard
            Westman, Marion Rostotsky, Ricci Shen
          </p>
          <p className="lower-item">
            <strong>{texts[language].photo}</strong> Caterina Fanti
          </p>
          <p className="lower-item">
            <strong>{texts[language].montage}</strong> Lucìa Diaz Losantos
          </p>
          <p className="lower-item">
            <strong>{texts[language].dirAssist}</strong> Natacha Delatine,
            Samuel Hammaström, Álvaro Aguiló, Wille Gunnesson
          </p>
          <p className="lower-item">
            <strong>{texts[language].productor}</strong> Mila Van Gelder
          </p>
          <p className="lower-item">
            <strong>{texts[language].camAssist}</strong>Lucià Diaz Losantos
          </p>
          <p className="lower-item">
            <strong>{texts[language].dirLuci}</strong>Robin Yu Schmersel
          </p>
          <p className="lower-item">
            <strong>{texts[language].assistLuci}</strong>Curtis Lee Terry
          </p>
          <p className="lower-item">
            <strong>{texts[language].script}</strong>Daniella Strömgren
          </p>
          <p className="lower-item">
            <strong>{texts[language].tecSuono}</strong>Marco Russo
          </p>
          <p className="lower-item">
            <strong>{texts[language].runner}</strong>Pierre Bonneuil
          </p>
        </div>
      </div>
    </div>
  );
}
