import SecondaryNavbar from "../../components/SecondaryNavbar";
import { LanguageContext } from "../../LanguageContext";
import React, { useContext } from "react";
import Kyle from "../../images/kyledefinitivo.png";
import "../ProjectPage/projectpage.css";

export default function ProjectPage() {
  const { language } = useContext(LanguageContext);
  const texts = {
    ita: {
      description:
        "Kyle è ciò che ha svoltato la mia carriera. Mi ero trasferito a Bruxelles da qualche mese per imparare il francese, con lo specifico obbiettivo di lavorare un giorno con lo storico editore Dupuis. Un misto di fortuna e audacia mi ha fatto entrare in contatto con un autore che è stato così gentile da inviare al suo editor il proposal sul quale avevo lavorato per più di un anno con Alessandro Zonno, giovanissimo disegnatore appena uscito dalla Scuola Internazionale di Comics di Torino. Benoît ha da subito amato il progetto, ma noi l’abbiamo fatto dannare cambiando la storia e lo stile di disegno un miliardo di volte. Ma lui, insieme a Laure, ci ha sempre supportato, siamo stati molto fortunati. Con Alessio ci sono stati alti e bassi, ma quando abbiamo trovato la sintonia il progetto è decollato. Nell’albo si può vedere chiaramente quando io e Ale ci siamo allineati, i disegni sono diventati da buoni a ottimi. I colori di Luca Pinelli, dopo mesi di lavoro per trovare lo stile giusto, elevano il risultato ulteriormente. Il risultato finale mi riempie di orgoglio, nonostante la storia possa a volte risultare un po’ confusa. È stato un lungo percorso, che si spera vada avanti per anni con nuovi volumi.",
      scenography: "Sceneggiatura: ",
      drawings: "Disegni: ",
      colours: "Colori: ",
    },
    eng: {
      description:
        "Kyle’s what turned my career around. I had moved to Brussels for a few months to learn French, with the specific aim of working one day with the great publisher Dupuis. A mixture of luck and audacity made me get in touch with an author who was kind enough to send his editor the proposal on which I had worked for more than a year with Alessio Zonno, a very young artist just out of the International School of Comics in Turin. Benoît immediately loved the project, but we made him damned by changing the story and visual style a billion times. But he, along with Laure, always supported us, we have been very lucky. With Alessio there were ups and downs, but when we found the harmony the project took off. In the book you can see clearly when Ale and I aligned, the art turned from good to great. The colors of Luca Pinelli, after months of work to find the right style, elevate the result further. The final result makes me very proud, although the story can sometimes be a bit confusing. It has been a long journey, hope fully going on for years with new volumes.",
      scenography: "Writer: ",
      drawings: "Art: ",
      colours: "Colours: ",
    },
    fra: {
      description:
        "Kyle est ce qui a changé ma carrière. J’avais déménagé à Bruxelles il y a quelques mois pour apprendre le français, avec l’objectif spécifique de travailler un jour avec Les Éditions Dupuis. Un mélange de chance et d’audace m’a fait entrer en contact avec un auteur qui a eu la gentillesse d’envoyer à son éditeur le dossier sur lequel j’avais travaillé pendant plus d’un an avec Alessandro Zonno, un jeune dessinateur vient de sortir de l’École Internationale de Comics de Turin. Benoît a tout de suite aimé le projet, mais nous l’avons fait damner en changeant l’histoire et le style de dessin un milliard de fois. Mais lui, avec Laure, nous a toujours soutenus, nous avons eu beaucoup de chance. Avec Alessio, il y a eu des hauts et des bas, mais quand nous avons trouvé l’accord, le projet a décollé. Dans les page, on peut voir clairement quand Ale et moi nous sommes alignés, les dessins sont devenus excellents. Les couleurs de Luca Pinelli, après des mois de travail pour trouver le bon style, rehaussent encore plus le produit. Je suis très fier du résultat final, bien que l’histoire puisse parfois être un peu confuse. Ce fut un long chemin, qui, espérons-le, durera des années avec de nouveaux volumes.",
      scenography: "Scénario: ",
      drawings: "Dessins: ",
      colours: "Couleurs: ",
    },
  };
  return (
    <div className="project-page-wrapper">
      <SecondaryNavbar />
      <div className="project-page-left">
        <img className="paris-image" src={Kyle} alt="kyle" />
        <p className="project-description">{texts[language].description}</p>
      </div>
      <div className="faccia-da-morto-right">
        <div className="right-items">
          <p className="upper-item">KYLE TRAVEL</p>
          <p className="upper-item">2023</p>
          <p className="upper-item">Scenography</p>
          <p className="upper-item">Dupuis</p>
        </div>
        <p className="half-list"></p>
        <div className="left-items">
          <p className="lower-item">
            <strong>{texts[language].scenography}</strong> Marco Russo
          </p>

          <p className="lower-item">
            <strong>{texts[language].drawings}</strong>Alessio Zonno
          </p>
          <p className="lower-item">
            <strong>{texts[language].colours}</strong>Luca Pinelli
          </p>
        </div>
      </div>
    </div>
  );
}
