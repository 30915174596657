import SecondaryNavbar from "../../components/SecondaryNavbar";
import { LanguageContext } from "../../LanguageContext";
import React, { useContext } from "react";
import PFSM from "../../images/PFS1-1.png";
import "../ProjectPage/projectpage.css";

export default function PSFM() {
  const { language } = useContext(LanguageContext);
  const texts = {
    ita: {
      description:
        "Il mio primo, vero progetto a fumetti. Tutto è nato col lancio da parte di Panini Comics di un contest per progetti a fumetti. In una settimana, io e Alessio Fioriniello abbiamo creato un proposal niente male. Siamo stati selezionati a Lucca Comics e ho dovuto fare il mio primo pitch in fronte alla redazione, rimanendo sorpreso di quanto io sia stato in grado di essere calmo e professionale. L’idea per la storia è nata dopo aver visto delle illustrazioni di Bastien Vivès, rivisitazioni di personaggi dei Street Fighters al femminile. Il mio cervello ha fatto un’inconsueta associazione d’idee: wrestling + alta moda. Ne è nata una critica sociale dal mio personalissimo punto di vista, trattando di body shaming e parità di genere. Una storia bizzarra e sicuramente non perfetta, che però mi ha fatto capire che mi piace trattare temi complicati, cercando di renderli pop e appetibili a un pubblico generalista. La collaborazione con Ale ha avuto i suoi momenti buii, ma quando si ha la fortuna di lavorare con un artista del suo calibro è più semplice frenare gli istinti omicidi e riconoscere che il proprio punto di vista non è necessariamente quello giusto. Fine della storia edificante: purtroppo il volume è uscito a tre giorni dal primo lockdown dovuto al COVID, le copie vendute si contano sulle dita delle mani mie e di Alessio, non ci abbiamo guadagnato granché dal punto di vista economico e neanche in visibilità. Si ricomincia da capo, ma è comunque una storia che mi fa divertire.",
      scenography: "Sceneggiatura: ",
      drawings: "Disegni e colori: ",
      role: "Sceneggiatura",
      location: "Italia",
    },
    eng: {
      description:
        "My first real comic book project. It all started with the launch of a contest by Panini Comics. In a week, Alessio Fioriniello and I created a pretty good proposal. We were selected at Lucca Comics and I had to make my first pitch in front of the editorial staff, being surprised at how calm and professional I was. The idea for the story was born after seeing illustrations by Bastien Vivès, revisitations of female Street Fighters characters. My brain has made an unusual association of ideas: wrestling + haute couture. The result was a social criticism from my very personal point of view, dealing with body shaming and gender equality. A bizarre and certainly not perfect story, but it made me realize that I like to deal with complicated themes, trying to make them pop and appealing to a general audience. The collaboration with Ale has had its dark moments, but when you are lucky enough to work with an artist of his caliber it is easier to curb the murderous instincts and recognize that your point of view is not necessarily the right one. End of the happy story: unfortunately the volume was published three days before the first COVID lockdown in Italy, the units sold are counted on the fingers of my and Alessio’s hands, we did not gain much economically and not even in visibility. We had to start from scratch again, but it’s still a fun story.",
      scenography: "Writer: ",
      drawings: "Art and Colours: ",
      role: "Writer",
      location: "Italy",
    },
    fra: {
      description:
        "Mon vrai premier projet de bande dessinée. Tout est né avec le lancement d’un concours par Panini Comics. En une semaine, Alessio Fioriniello et moi avons créé une dossier pas mal. Nous avons été sélectionnés à Lucca Comics et j’ai dû faire mon premier pitch devant la rédaction, en étant surpris de voir à quel point j’ai pu être calme et professionnel. L’idée de l’histoire est née après avoir vu des illustrations de Bastien Vivès, revisitations de personnages de Street Fighters au féminin. Mon cerveau a fait une association inhabituelle d’idées : lutte + haute couture. Cela a donné lieu à une critique sociale de mon point de vue très personnel, traitant du body shaming et de l’égalité des genres. Une histoire étrange et certainement pas parfaite, mais qui m’a fait comprendre que j’aime traiter des sujets compliqués, en essayant de les rendre pop et attractifs pour un public généraliste. La collaboration avec Ale a eu ses moments sombres, mais quand on a la chance de travailler avec un artiste de son calibre, il est plus facile de freiner les instincts meurtriers et de reconnaître que mon point de vue n’est pas nécessairement le bon. Fin de l’histoire édifiante : malheureusement le volume est sorti trois jours après le premier confinement dû au COVID, les copies vendues se comptent sur les doigts des mains à moi et Alessio, nous n’avons pas gagné grand-chose d’un point de vue économique et même pas en visibilité. On a du repartir à zéro, mais c’est quand même une histoire qui m’amuse.",
      scenography: "Scénariste: ",
      drawings: "Dessins et Couleurs: ",
      role: "Scénariste",
      location: "Italie",
    },
  };
  return (
    <div className="project-page-wrapper">
      <SecondaryNavbar />
      <div className="project-page-left">
        <img className="paris-image" src={PFSM} alt="kyle" />
        <p className="project-description">{texts[language].description}</p>
      </div>
      <div className="project-page-right">
        <div className="right-items">
          <p className="upper-item">PARIS FASHION SLAM</p>
          <p className="upper-item">2020</p>
          <p className="upper-item">{texts[language].role}</p>
          <p className="upper-item">Panini Comics</p>
          <p className="upper-item">{texts[language].place}</p>
          <p className="upper-item">{texts[language].location}</p>
        </div>
        <p className="half-list"></p>
        <div className="left-items">
          <p className="lower-item">
            <strong>{texts[language].scenography}</strong> Marco Russo
          </p>

          <p className="lower-item">
            <strong>{texts[language].drawings}</strong>Alessio Fiorniniello
          </p>

          <p className="lower-item">
            <strong>Editor: </strong>Isaak Friedl
          </p>
        </div>
      </div>
    </div>
  );
}
