import React, { useContext, useState } from "react";
import { LanguageContext } from "../../LanguageContext";
import "./contact.css";
import emailjs from "emailjs-com";
import Logo from "../../images/logoultimate.png";
import { Link } from "react-router-dom";

const Contact = () => {
  const { language } = useContext(LanguageContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name,
      email,
      message,
    };

    emailjs
      .send(
        "service_io72s7z",
        "template_ta2q9xa",
        templateParams,
        "Ux_EyVYKQ_Oixba6X"
      )
      .then((response) => {
        console.log("Email sent successfully!");
        setShowConfirmation(true);
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const texts = {
    ita: {
      title: "Contattami",
      nameLabel: "Nome",
      emailLabel: "Email",
      messageLabel: "Messaggio",
      submitButton: "Invia",
      confirmationMessage: "Messaggio inviato con successo!",
    },
    eng: {
      title: "Contact Me",
      nameLabel: "Name",
      emailLabel: "Email",
      messageLabel: "Message",
      submitButton: "Submit",
      confirmationMessage: "Message sent successfully!",
    },
    fra: {
      title: "Contactez-moi",
      nameLabel: "Nom",
      emailLabel: "Email",
      messageLabel: "Message",
      submitButton: "Envoyer",
      confirmationMessage: "Message envoyé avec succès !",
    },
  };

  return (
    <div className="contact-page">
      <div className="logo-div">
        <Link to="/">
          <img src={Logo} alt="logo" className="marcorusso" />
        </Link>
      </div>
      <div className="contact-container">
        <h2 className="contact-title">{texts[language].title}</h2>
        {showConfirmation && (
          <p className="confirmation-message">
            {texts[language].confirmationMessage}
          </p>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">{texts[language].nameLabel}</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">{texts[language].emailLabel}</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">{texts[language].messageLabel}</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
          <button className="submit-button" type="submit">
            {texts[language].submitButton}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
