import SecondaryNavbar from "../../components/SecondaryNavbar";
import { LanguageContext } from "../../LanguageContext";
import React, { useContext } from "react";
import QuantumAcademypng from "../../images/QuantumAcademy.png";
import "../ProjectPage/projectpage.css";

export default function QuantumAcademy() {
  const { language } = useContext(LanguageContext);
  const texts = {
    ita: {
      description:
        "Una delle mie prime pubblicazioni ufficiali. Luca Baino mi chiese se volevo collaborare a questa raccolta antologica ambientata nel mondo di Quantum Academy e non potevo rifiutare. Abbiamo lavorato assieme anche con Marco Ventura su questa idea di torneo e sui nuovi personaggi. Personalmente ho creato il personaggio di Max, che è anche nella splendida copertina realizzata da Claudia Iannicello.",
      writers: "Sceneggiatura: ",
      drawings: "Disegni: ",
      lettering: "Lettering: ",
      coverart: "Copertina: ",
      role: "Sceneggiatore",
      place: "Italia",
    },
    eng: {
      description:
        "One of my first official publications. Luca Baino asked me if I wanted to collaborate on this anthology set in the world of Quantum Academy and I couldn’t refuse. We also worked together with Marco Ventura on this big tournament idea and on the new characters. I personally created Max, the characer that is also on the beautiful cover made by Claudia Iannicello.",
      writers: "Writers: ",
      drawings: "Drawings: ",
      lettering: "Lettering: ",
      coverart: "Couverture: ",
      role: "Writer",
      place: "Italy",
    },
    fra: {
      description:
        "Une de mes premières publications officielles. Luca Baino m’a demandé si je voulais collaborer à cette collection anthologique qui se déroule dans le monde de Quantum Academy et je ne pouvais pas refuser. Nous avons également travaillé avec Marco Ventura sur cette idée de tournoi et sur les nouveaux personnages. J’ai personnellement créé le personnage de Max, qui est aussi dans la magnifique couverture réalisée par Claudia Iannicello.",
      writers: "Scénario: ",
      drawings: "Dessins: ",
      lettering: "Lettrage: ",
      coverart: "Couverture: ",
      role: "Scénariste",
      place: "Italie",
    },
  };
  return (
    <div className="project-page-wrapper">
      <SecondaryNavbar />
      <div className="project-page-left">
        <img
          className="paris-image "
          src={QuantumAcademypng}
          alt="quantuacademy"
        />
        <p className="project-description">{texts[language].description}</p>
      </div>
      <div className="project-page-right">
        <div className="right-items">
          <p className="upper-item">QUANTUM ACADEMY APOCRYPHA</p>
          <p className="upper-item">2017</p>
          <p className="upper-item">{texts[language].role}</p>
          <p className="upper-item">Manfont</p>
          <p className="upper-item">{texts[language].place}</p>
        </div>
        <p className="half-list"></p>
        <div className="left-items">
          <p className="lower-item">
            <strong>{texts[language].writers}</strong> Luca Baino, Marco Russo,
            Marco Ventura, Lorenzo Susi
          </p>

          <p className="lower-item">
            <strong>{texts[language].drawings}</strong>Mario Torrisi, Giada
            Fiorani, Lorenzo Tammetta, Vladimir Burga, Giovanni Panarello,
            Federica Casabona, Roberta Picchieri, Elia Di Padova, Maria
            Valentini, Francesca Archidiacono, Ennio Maltese
          </p>
          <p className="lower-item">
            <strong>{texts[language].lettering}</strong>Marco Simeoni
          </p>
          <p className="lower-item">
            <strong>{texts[language].coverart}</strong>Claudia Iannicello
          </p>
        </div>
      </div>
    </div>
  );
}
