import SecondaryNavbar from "../../components/SecondaryNavbar";
import { LanguageContext } from "../../LanguageContext";
import React, { useContext } from "react";
import "../ProjectPage/projectpage.css";
import YouTube from "react-youtube";

export default function NTP() {
  const { language } = useContext(LanguageContext);
  const texts = {
    ita: {
      description:
        "Questo progetto fa parte di un esercizio chiamato “Shortcuts”. La classe ha prodotto un cortometraggio al giorno per una settimana, ruotando tutti i ruoli della produzione all’interno del team. Questo è quello che ho scritto e diretto. Volevo provare qualcosa di particolare, ho scritto la storia in funzione della tecnica che volevo applicare: volevo avere un fantasma in 'camera', senza l’uso di effetti digitali. Abbiamo usato una grossa lastra di vetro per riflettere l’immagine dell’attore, che recitava parecchio distante dall’altro. L’effetto è carino, sono contento di aver provato. Al netto di un risultato non perfetto, è stato bello aver usato una tecnica particolare, mai provata nella scuola. L’aspetto tecnico forse è stato anche troppo dispendioso, avrei probabilmente dovuto concentrarmi di più sulla recitazione, ma tutto sommato ho imparato molto ed è stato un passo avanti sulla strada della regia. Ho anche montato il corto, ho imparato tanto tecnicamente e ho scoperto che adoro il montaggio.",
      director: "Direttore, sceneggiatore, editore: ",
      photo: "Direttore di fotografia: ",
      mounting: "Montaggio: ",
      dirAssist: "Assistente di regia: ",
      cameraAssist: "Assistente camera: ",
      lights: "Luci: ",
      action: "Segretaria d'azione: ",
      sound: "Tecnico del suono: ",
      role: "Regia e Sceneggiatura",
    },
    eng: {
      description:
        "This project is part of an exercise called 'Shortcuts'. The class produced one short film a day for a week, rotating all the production roles within the team. This is what I wrote and directed. I wanted to try something particular, I wrote the story according to the technique I wanted to apply: I wanted to have a ghost in 'camera,' without the use of digital effects. We used a large sheet of glass to reflect the image of the actor, who was acting quite far away from each other. The effect is nice; I'm glad I tried it. Net of a less-than-perfect result, it was nice to have used a particular technique, never tried in school. The technical aspect was also perhaps too time-consuming, I probably should have focused more on acting, but all in all I learned a lot and it was a step on the road to directing. I also edited the short film, learned a lot technically and discovered that I love editing.",
      director: "Director, screenwriter, editor: ",
      photo: "Photography Director: ",
      mounting: "Mounting: ",
      dirAssist: "Directory assistant: ",
      cameraAssist: "Camera assistant: ",
      lights: "Lights: ",
      action: "Publishing secretary: ",
      sound: "Sound technician: ",
      role: "Writer and Director",
    },
    fra: {
      description:
        "Ce projet fait partie d'un exercice appelé 'Shortcuts'. La classe a produit un court métrage par jour pendant une semaine, en alternant tous les rôles de production au sein de l'équipe. C'est ce que j'ai écrit et réalisé. Je voulais essayer quelque chose de spécial, j'ai écrit l'histoire en fonction de la technique que je voulais appliquer : je voulais avoir un fantôme en 'caméra', sans utiliser d'effets numériques. Nous avons utilisé une grande feuille de verre pour refléter l'image de l'acteur, qui jouait assez loin. L'effet est sympa, je suis content d'avoir essayé. Même si le résultat n'est pas parfait, c'est bien d'avoir utilisé une technique spéciale qui n'avait jamais été essayée à l'école. L'aspect technique a peut-être pris trop de temps, j'aurais probablement dû me concentrer davantage sur le jeu d'acteur, mais dans l'ensemble, j'ai beaucoup appris et c'est un pas en avant sur la voie de la réalisation. J'ai également monté le court métrage, j'ai beaucoup appris sur le plan technique et j'ai découvert que j'aimais le montage.",
      director: "Réalisateur, scénariste, monteur: ",
      photo: "Directeur de la photographie: ",
      dirAssist: "Assistant d'annuaire: ",
      cameraAssist: "Assistant caméra: ",
      lights: "Lumières: ",
      sound: "Technicien du son: ",
      role: "Scénariste et Réalisateur",
    },
  };
  return (
    <div className="project-page-wrapper">
      <SecondaryNavbar />
      <div className="project-page-video-left">
        <YouTube
          videoId="A8i7NSV6GQo" // Replace with the YouTube video ID you want to display
          className="project-page-video" // Add a class name for styling if needed
        />
        <p className="project-video-description">
          {texts[language].description}
        </p>
      </div>
      <div className="project-page-right">
        <div className="right-items">
          <p className="upper-item">NOTHING TO PROVE</p>
          <p className="upper-item">2022</p>
          <p className="upper-item">{texts[language].role}</p>
          <p className="upper-item">Stockholm Filmskola</p>
        </div>
        <p className="half-list"></p>
        <div className="left-items">
          <p className="lower-item">
            <strong>{texts[language].director}</strong> Marco Russo
          </p>
          <p className="lower-item">
            <strong>Cast: </strong>Jakob Petrén e Gabriel King
          </p>
          <p className="lower-item">
            <strong>{texts[language].photo}</strong>Caterina Fanti
          </p>
          <p className="lower-item">
            <strong>{texts[language].dirAssist}</strong>Sarah Schäfer
          </p>
          <p className="lower-item">
            <strong>{texts[language].cameraAssist}</strong>Greta Fazzi
          </p>
          <p className="lower-item">
            <strong>{texts[language].lights}</strong>Niklas Dopheide
          </p>
          <p className="lower-item">
            <strong>{texts[language].sound}</strong>Mila Van Gelder
          </p>
        </div>
      </div>
    </div>
  );
}
