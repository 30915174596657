import SecondaryNavbar from "../../components/SecondaryNavbar";
import { LanguageContext } from "../../LanguageContext";
import React, { useContext } from "react";
import "./projectpage.css";
import YouTube from "react-youtube";

export default function ProjectPage() {
  const { language } = useContext(LanguageContext);

  const texts = {
    ita: {
      description:
        "Questo è il primo progetto che io abbia mai diretto, nonché uno dei primissimi esercizi alla Stockholms Filmskola. Era un esercizio di cinematography, ai registi era stato detto di lasciare campo libero ai DoP per permettergli di imparare. È stata un’esperienza intensa, ero molto nervoso e la collaborazione con Oskar è stata a tratti faticosa perché non avevo la sicurezza necessaria per imporre le mie idee. A distanza di tempo, tuttavia, riguardo questo breve esperimento con soddisfazione. Sono fiero della storia e di quello che abbiamo creato in un solo giorno, inesperti, con esclusivamente luce naturale (non eravamo ancora in grado di usare luci artificiali) e attori improvvisati.",
      director: "Direttore: ",
      photo: "Direttore di fotografia: ",
      mounting: "Montaggio: ",
      dirAssist: "Assistente di regia: ",
      cameraAssist: "Assistente camera: ",
      lights: "Luci: ",
      action: "Segretaria d'azione: ",
      sound: "Tecnico del suono: ",
      role: "Regia e Sceneggiatura",
    },
    eng: {
      description:
        "This is the first project I’ve ever directed and one of the very first exercises at Stockholms Filmskola. It was a cinematography exercise, the filmmakers were told to leave space to the DoP to allow them to learn. It was an intense experience, I was very nervous and the collaboration with Oskar was hard at times because I did not have the confidence to impose my ideas. After a while, however, I can look at this brief experiment with satisfaction. I am proud of the story and of what we have created in one day, inexperienced, with exclusively natural light (we were not yet able to use artificial lights) and improvised actors.",
      director: "Director: ",
      photo: "Photography Director: ",
      mounting: "Mounting: ",
      dirAssist: "Directory assistant: ",
      cameraAssist: "Camera assistant: ",
      lights: "Lights: ",
      action: "Publishing secretary: ",
      sound: "Sound technician: ",
      role: "Director and Writer",
    },
    fra: {
      description:
        "Celui-ci est le premier projet que j’ai jamais réalisé et l’un des tout premiers exercices à Stockholms Filmskola. C’était un exercice de cinématographie, on a dit aux étudiants de laisser de l’espace aux directeurs de la photographie pour leur permettre d’apprendre. C’était une expérience intense, j’étais très nerveux et la collaboration avec Oskar était parfois difficile parce que je n’avais pas la confiance nécessaire pour imposer mes idées. Après un certain temps, cependant, je peux regarder cette brève expérience avec satisfaction. Je suis fier de l’histoire et de ce que nous avons créé en une journée, sans aucune expérience, avec exclusivement de la lumière naturelle (nous n’étions pas encore capables d’utiliser des lumières artificielles) et des acteurs improvisés.",
      director: "Réalisateur et Scénariste: ",
      photo: "Directeur de la photographie: ",
      mounting: "Assemblée: ",
      dirAssist: "Assistant d'annuaire: ",
      cameraAssist: "Assistant caméra: ",
      lights: "Lumières: ",
      action: "Secrétaire d'édition: ",
      sound: "Technicien du son: ",
      role: "Réalisateur et Scénariste",
    },
  };
  return (
    <div className="project-page-wrapper">
      <SecondaryNavbar />
      <div className="project-page-video-left">
        <YouTube
          videoId="gVP3bskr0K4" // Replace with the YouTube video ID you want to display
          className="project-page-video"
        />
        <p className="project-video-description">
          {texts[language].description}
        </p>
      </div>
      <div className="project-page-right">
        <div className="right-items">
          <p className="upper-item">SHOES</p>
          <p className="upper-item">2022</p>
          <p className="upper-item">{texts[language].role}</p>
          <p className="upper-item">Stockholm Filmskola</p>
        </div>
        <p className="half-list"></p>
        <div className="left-items">
          <p className="lower-item">
            <strong>{texts[language].director}</strong> Marco Russo
          </p>
          <p className="lower-item">
            <strong>Cast: </strong>Hugo Lindstrom e Hampus Svensson
          </p>
          <p className="lower-item">
            <strong>{texts[language].photo}</strong>Oskar Amundandasson
          </p>
          <p className="lower-item">
            <strong>{texts[language].mounting}</strong>Lucia Diaz Los Santos
          </p>
          <p className="lower-item">
            <strong>{texts[language].dirAssist}</strong>Niklas Dopheide
          </p>
          <p className="lower-item">
            <strong>{texts[language].cameraAssist}</strong>Polina Rubis
          </p>
          <p className="lower-item">
            <strong>{texts[language].lights}</strong>Ricci Shen
          </p>
          <p className="lower-item">
            <strong>{texts[language].action}</strong>Sarah Schäfer
          </p>
          <p className="lower-item">
            <strong>{texts[language].sound}</strong>Lucía Diaz Losantos
          </p>
        </div>
      </div>
    </div>
  );
}
