import SecondaryNavbar from "../../components/SecondaryNavbar";
import { LanguageContext } from "../../LanguageContext";
import React, { useContext } from "react";
import Atersaadpng from "../../images/atersadd.png";
import "../ProjectPage/projectpage.css";

export default function Atersaad() {
  const { language } = useContext(LanguageContext);
  const texts = {
    ita: {
      description:
        "Questa collaborazione è venuta fuori un po’ per caso. Stavo girando alcune scene di un documentario in House Blivande a Stoccolma e facendo un tour del luogo ho notato questa stanza dedicata alle riprese di un film animato in stop-motion. Ho immediatamente chiesto allo staff di mettermi in contatto con il regista, che fortunatamente ha accettato di prendermi come stagista per la conclusione delle riprese. È stata un’esperienza fantastica, ho imparato molto aiutandolo in ogni modo mi fosse possibile con le mie limitate conoscenze nel campo dell’animazione. Sono da sempre appassionato di animazione e in particolare dello stop-motion, quindi spero che questo sia solo un primo passo in questo mondo magico.",
      director:
        "Sceneggiatura, design, puppet maker, scenografie, animazione: ",
      dirAssist: "Assistente animatore: ",
      place: "Svezia",
      role: "Assistente animatore",
    },
    eng: {
      description:
        "This collaboration came up by accident. I was shooting some scenes of a documentary in House Blivande in Stockholm and doing a tour of the place I noticed this room dedicated to shooting an animated film in stop-motion. I immediately asked the staff to put me in touch with the director, who fortunately agreed to take me as an intern for the conclusion of the shooting. It was a fantastic experience, I learned a lot helping him in every way I could. I have always been passionate about animation and especially stop-motion, so I hope this is just a first step in this magical world.",
      director: "Script, design, puppet making, scenography, animation: ",
      dirAssist: "Assistant animator: ",
      place: "Sweden",
      role: "Assistant animator",
    },
    fra: {
      description:
        "Cette collaboration est arrivée un peu par hasard. Je tournais quelques scènes d’un documentaire dans House Blivande à Stockholm et en faisant une visite du lieu, j’ai remarqué cette salle dédiée au tournage d’un film animé en stop-motion. J’ai immédiatement demandé à l’équipe de me mettre en contact avec le réalisateur, qui a heureusement accepté de me prendre comme stagiaire pour la fin du tournage. C’était une expérience fantastique, j’ai beaucoup appris en l’aidant autant que possible avec mes connaissances limitées dans le domaine de l’animation. Je suis toujours passionné par l’animation et en particulier le stop-motion, j’espère donc que ce n’est qu’une première étape dans ce monde magique.",
      director: "Scénario, design, marionnette, scénographies, animation: ",
      dirAssist: "Assistant à l’animation: ",
      place: "Suède",
      role: "Assistant à l’animation",
    },
  };
  return (
    <div className="project-page-wrapper">
      <SecondaryNavbar />
      <div className="aaterstad-page-left">
        <img className="aaterstad-image" src={Atersaadpng} alt="shoes" />
        <p className="aaterstad-project-description">
          {texts[language].description}
        </p>
      </div>
      <div className="project-page-right">
        <div className="right-items">
          <p className="upper-item">ÅTERSÅDD</p>
          <p className="upper-item">2022</p>
          <p className="upper-item">{texts[language].role}</p>
          <p className="upper-item">{texts[language].place}</p>
        </div>
        <p className="half-list"></p>
        <div className="left-items">
          <p className="lower-item">
            <strong>{texts[language].director}</strong> Emanu
          </p>

          <p className="lower-item">
            <strong>{texts[language].dirAssist}</strong>Marco Russo
          </p>
        </div>
      </div>
    </div>
  );
}
