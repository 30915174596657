import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import italianFlag from "../images/italianflag.png";
import ukFlag from "../images/ukflag.png";
import frenchFlag from "../images/frenchflag.png";
import Logo from "../images/logoafilo.png";
import { ReactComponent as TwitterSVG } from "../images/icons8-twitter.svg";
import { ReactComponent as LikedinSVG } from "../images/icons8-linkedin.svg";
import { ReactComponent as InstagramSVG } from "../images/icons8-instagram.svg";
import "./navbar.css";
import { LanguageContext } from "../LanguageContext";

export default function Navbar({ onCategorySelect }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const { language, changeLanguage } = useContext(LanguageContext);
  const location = useLocation();

  const handleLanguageChange = (newLanguage) => {
    changeLanguage(newLanguage);
    setMenuOpen(false);
  };

  const getLanguageFlag = (language) => {
    switch (language) {
      case "ita":
        return italianFlag;
      case "eng":
        return ukFlag;
      case "fra":
        return frenchFlag;
      default:
        return ukFlag; // Imposta l'immagine di default per la lingua inglese
    }
  };

  const texts = {
    ita: {
      about: "Chi sono",
      contact: "Contatti",
      film: "Film",
      comics: "Fumetti",
      animation: "Animazione",
      advertising: "Pubblicità",
      all: "Tutte",
    },
    eng: {
      about: "About",
      contact: "Contact",
      film: "Film",
      comics: "Comics",
      animation: "Animation",
      advertising: "Advertising",
      all: "All",
    },
    fra: {
      about: "À propos",
      contact: "Contact",
      film: "Films",
      comics: "BD",
      animation: "Animation",
      advertising: "Publicité",
      all: "Tous",
    },
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <Link to="/">
        <img
          src={Logo}
          className="logo"
          alt="logo"
          onClick={() => onCategorySelect("")}
        />
      </Link>
      {location.pathname === "/" && (
        <div className="categories">
          {/*<button className="category" onClick={() => onCategorySelect("")}>
            {texts[language].all}
      </button>*/}
          <button
            className="category"
            onClick={() => onCategorySelect("cinema")}
          >
            {texts[language].film}
          </button>
          <button
            className="category"
            onClick={() => onCategorySelect("comics")}
          >
            {texts[language].comics}
          </button>
          <button
            className="category"
            onClick={() => onCategorySelect("animation")}
          >
            {texts[language].animation}
          </button>
          <button
            className="category"
            onClick={() => onCategorySelect("advertising")}
          >
            {texts[language].advertising}
          </button>
        </div>
      )}
      <div className="social-icons">
        <a
          href="https://twitter.com/MrMarcoRusso"
          target="blank"
          rel="noopener"
        >
          <TwitterSVG className="social-icon" />
        </a>
        <a
          href="https://www.linkedin.com/in/russomarcobd/"
          target="blank"
          rel="noopener"
        >
          <LikedinSVG className="social-icon" />
        </a>
        <a
          href="https://www.instagram.com/russo.marcolo/"
          target="blank"
          rel="noopener"
        >
          <InstagramSVG className="social-icon" />
        </a>
      </div>

      <div className="link-language-div">
        <Link to="/about" className="page-link">
          {texts[language].about}
        </Link>

        <Link to="/contact" className="page-link">
          <p className="page-link">{texts[language].contact}</p>
        </Link>

        <img
          src={getLanguageFlag(language)}
          alt="menu-icon"
          className="flag-icon"
          onClick={toggleMenu}
        />

        <div className={`language-icons ${menuOpen ? "open" : ""}`}>
          <img
            src={italianFlag}
            alt="italian flag"
            className="flag-icon"
            onClick={() => handleLanguageChange("ita")}
          />

          <img
            src={ukFlag}
            alt="uk flag"
            className="flag-icon"
            onClick={() => handleLanguageChange("eng")}
          />

          <img
            src={frenchFlag}
            alt="french flag"
            className="flag-icon"
            onClick={() => handleLanguageChange("fra")}
          />
        </div>
      </div>
    </nav>
  );
}
