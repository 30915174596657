import SecondaryNavbar from "../../components/SecondaryNavbar";
import { LanguageContext } from "../../LanguageContext";
import React, { useContext } from "react";
import rifiutipng from "../../images/rifiuti.png";
import "../ProjectPage/projectpage.css";

export default function Rifiuti() {
  const { language } = useContext(LanguageContext);
  const texts = {
    ita: {
      description:
        "Storia creata con Tommaso Lucchetti per un concorso mai vinto, non ricordo neanche più quale. Sono ancora tuttavia convinto del valore di questa piccola storia e dei disegni di Tommy, considerando che al tempo eravamo entrambi studenti della Scuola Internazionale di Comics.",
      writers: "Sceneggiatura e Lettering: ",
      drawings: "Disegni: ",
      role: "Sceneggiatore",
      place: "Italia",
      title: "RIFIUTI",
    },
    eng: {
      description:
        "Story created with Tommaso Lucchetti for a contest we never won, I don’t even remember which one. I am still convinced, however, of the value of this little story and of Tommy’s art, considering that at the time we where both students of the International School of Comics.",
      writers: "Screenplay and Lettering: ",
      drawings: "Drawings: ",
      role: "Writer",
      place: "Italy",
      title: "WASTE",
    },
    fra: {
      description:
        "Histoire créée avec Tommaso Lucchetti pour un concours jamais gagné, je ne me souviens même plus lequel. Je suis cependant encore convaincu de la valeur de cette petite histoire et des dessins de Tommy, considérant qu’à l’époque, nous étions tous les deux étudiants de l’École Internationale de Comics.",
      writers: "Scénariste: ",
      drawings: "Dessins: ",
      role: "Scénariste",
      place: "Italie",
      title: "DÉCHETS",
    },
  };
  return (
    <div className="project-page-wrapper">
      <SecondaryNavbar />
      <div className="project-page-left">
        <img className="paris-image" src={rifiutipng} alt="rifiuti" />
        <p className="project-description">{texts[language].description}</p>
      </div>
      <div className="project-page-right">
        <div className="right-items">
          <p className="upper-item">RIFIUTI</p>
          <p className="upper-item">2016</p>
          <p className="upper-item">{texts[language].role}</p>
          <p className="upper-item">{texts[language].place}</p>
        </div>
        <p className="half-list"></p>
        <div className="left-items">
          <p className="lower-item">
            <strong>{texts[language].writers}</strong> Marco Russo
          </p>

          <p className="lower-item">
            <strong>{texts[language].drawings}</strong>Tommaso Lucchetti
          </p>
        </div>
      </div>
    </div>
  );
}
