import SecondaryNavbar from "../../components/SecondaryNavbar";
import { LanguageContext } from "../../LanguageContext";
import React, { useContext } from "react";
import Facciadamortopng from "../../images/facciadamorto.png";
import "../ProjectPage/projectpage.css";

export default function FacciaDaMorto() {
  const { language } = useContext(LanguageContext);
  const texts = {
    ita: {
      description:
        "La mia prima storia mai andata in stampa. Secondo posto al concorso per sceneggiatori dedicato al compianto Ade Capone, è ancora una delle cose più surreali che io abbia fatto. C’è anche una evidente citazione agli Iron Maiden. Più di una probabilmente. Sono molto legato a questa storia, la rileggo ancora con piacere nonostante io possa vederne i limiti. Ma è stato un bel primo passo.",
      writers: "Sceneggiatura: ",
      drawings: "Disegni: ",
      role: "Sceneggiatore",
      place: "Italia",
    },
    eng: {
      description:
        "My first story ever printed. Second place in the writers' competition in memory of Ade Capone, it’s still one of the most surreal things I’ve done. There is also an clear reference to the Iron Maiden. More than one probably. I’m very attached to this story, I still read it with pleasure although I can see the limits. But it was a nice first step.",
      writers: "Writers: ",
      drawings: "Drawings: ",
      role: "Writer",
      place: "Italy",
    },
    fra: {
      description:
        "Ma première histoire imprimée. Deuxième place dans le concours de scénaristes dédié à Ade Capone, c’est toujours l’une des choses les plus surréalistes que j’ai faites. Il y a aussi une référence évidente aux Iron Maiden. Plus d’une probablement. Je suis très attaché à cette histoire, je la relis toujours avec plaisir, bien que je puisse en voir les limites. Mais c’était un bon début.",
      writers: "Scénariste: ",
      drawings: "Dessins: ",
      role: "Scénariste",
      place: "Italie",
    },
  };
  return (
    <div className="project-page-wrapper">
      <SecondaryNavbar />
      <div className="project-page-left">
        <img
          className="paris-image"
          src={Facciadamortopng}
          alt="facciadamorto"
        />
        <p className="project-description">{texts[language].description}</p>
      </div>
      <div className="faccia-da-morto-right">
        <div className="right-items">
          <p className="upper-item">FACCIA DA MORTO</p>
          <p className="upper-item">2016</p>
          <p className="upper-item">{texts[language].role}</p>
          <p className="upper-item">Ora Pro Comics</p>
          <p className="upper-item">{texts[language].place}</p>
        </div>
        <p className="half-list"></p>
        <div className="left-items">
          <p className="lower-item">
            <strong>{texts[language].writers}</strong> Marco Russo
          </p>

          <p className="lower-item">
            <strong>{texts[language].drawings}</strong>Barbara Trinca
          </p>
        </div>
      </div>
    </div>
  );
}
