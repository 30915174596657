import SecondaryNavbar from "../../components/SecondaryNavbar";
import { LanguageContext } from "../../LanguageContext";
import React, { useContext } from "react";
import GelatoAmicopng from "../../images/GelatoAmico.png";
import "../ProjectPage/projectpage.css";

export default function GelatoAmico() {
  const { language } = useContext(LanguageContext);
  const texts = {
    ita: {
      description:
        "Può far ridere, ma questa è stat la mia prima vera pubblicazione. Tramite la Scuola Internazionale di Comics, io e il grande ????? Siamo stati incaricati di provare a realizzare una serie di strisce umoristiche promozionali per sponsorizzare l’apertura di una nuova gelateria a Torino. I titolari sono stati da subito molto aperti alle nostre idee, dandoci però una precisa reference: le strisce di Sio, uno stile che proprio non mi apparteneva. Ho dovuto studiare e adattarmi alle esigenze del cliente. È stato interessante e formativo, mi piace lavorare per il mondo pubblicitario/ promozionale.",
      scenography: "Sceneggiatura: ",
      drawings: "Disegni, Colori e Lettering: ",
      role: "Sceneggiatura",
      place: "Italia",
    },
    eng: {
      description:
        "It’s funny, but this was my very first publication. Through the International School of Comics, I and the great Loris Ravina were commissioned to create a series of promotional humor strips to sponsor the opening of a new ice cream shop in Turin. The owners were immediately very open to our ideas, giving us a precise reference though: the strips of Sio, a style that just did not belong to me, so I had to study and adapt to customer needs. It was interesting and educational, I like to work for the advertising world.",
      scenography: "Script: ",
      drawings: "Art, Colors and Lettering: ",
      role: "Writer",
      place: "Italy",
    },
    fra: {
      description:
        "C’est drôle, mais c’était ma première vraie publication. Grâce à l’École Internationale de Comics, le grand Loris Ravina et moi avons été chargés de réaliser une série de bandes humoristiques promotionnelles pour sponsoriser l’ouverture d’un nouveau glacier à Turin. Les propriétaires ont été immédiatement très ouverts à nos idées, mais nous donnant une référence précise : les bandes de Sio, un style qui ne m’appartenait pas. J’ai dû étudier et m’adapter aux besoins du client. C’était intéressant et formatif, j’aime travailler pour le monde publicitaire/promotionnel.",
      scenography: "Scénariste: ",
      drawings: "Dessins, Couleurs et Lettrage: ",
      role: "Scénariste",
      place: "Italie",
    },
  };
  return (
    <div className="project-page-wrapper">
      <SecondaryNavbar />
      <div className="project-page-left">
        <img className="paris-image" src={GelatoAmicopng} alt="kyle" />
        <p className="project-description">{texts[language].description}</p>
      </div>
      <div className="project-page-right">
        <div className="right-items">
          <p className="upper-item">GELATO AMICO</p>
          <p className="upper-item">2016</p>
          <p className="upper-item">{texts[language].role}</p>
          <p className="upper-item">Il Gelato Amico</p>
          <p className="upper-item">{texts[language].place}</p>
        </div>
        <p className="half-list"></p>
        <div className="left-items">
          <p className="lower-item">
            <strong>{texts[language].scenography}</strong> Marco Russo
          </p>

          <p className="lower-item">
            <strong>{texts[language].drawings}</strong>Loris Ravina
          </p>
        </div>
      </div>
    </div>
  );
}
