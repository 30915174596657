import SecondaryNavbar from "../../components/SecondaryNavbar";
import { LanguageContext } from "../../LanguageContext";
import React, { useContext } from "react";
import "../ProjectPage/projectpage.css";
import YouTube from "react-youtube";

export default function AEL() {
  const { language } = useContext(LanguageContext);
  const texts = {
    ita: {
      description:
        "Un altro Shortcut creato dalla mia classe, questa volta con la mia amica e compatriota Greta alla regia. Essendo lei una DoP principalmente, ha scritto ovviamente una storia che richiedeva un ambizioso set-up con le luci. Io ero il gaffer e avevamo un equipaggiamento limitato, quindi ho dovuto ingegnarmi per rispettare le richieste di Greta e la sua DoP Sarah. Abbiamo girato nel mio appartamento e abbiamo provato ad arrangiarci con quello che avevamo, usando anche practicals e tutto ciò che trovavamo in casa. Il risultato non è quello che ci eravamo ambiziosamente prefissati, ma comunque è stata una bella esperienza e ha confermato quanto io mi diverta con le luci.",
      director: "Sceneggiatura, Regia e Montaggio: ",
      casting: "Cast: ",
      photo: "Direttore della fotografia: ",
      realizzatore: "Assistente alla regia: ",
      operatore: "Assistente camera: ",
      dirLuci: "Capo elettricista: ",
      tecSuono: "Tecnico del suono: ",
      place: "Stockholm Filmskola",
      role: "Capo elettricista",
    },
    eng: {
      description:
        "Another Shortcut by my class, this time with my friend and compatriot Greta directing. Being primarily a cinematographer, she obviously wrote a story that required an ambitious lights set-up. I was the gaffer and we had limited equipment, so I had to work hard to fulfill the requests of Greta and her DoP Sarah. We shot in my apartment and tried to work with what we had, also using practicals and everything we found in the house. The result is not what we had set ourselves ambitiously, but it was a good experience and confirmed how much I enjoy working with lights.",
      director: "Writer, Director and Editor: ",
      casting: "Cast: ",
      photo: "Cinematographer: ",
      realizzatore: "Assistant Director: ",
      operatore: "Camera assistant: ",
      dirLuci: "Chief electrician: ",
      tecSuono: "Sound Operator: ",
      place: "Sweden",
      role: "Gaffer",
    },
    fra: {
      description:
        "Un autre Shortcut créé par ma classe, cette fois avec mon amie et compatriote Greta à la réalisation. Étant principalement directrice de la photographie, elle a évidemment écrit une histoire qui nécessitait un set-up ambitieux avec les lumières. J’étais le gaffer et nous avions un équipement limité, donc j’ai dû travailler dur pour répondre aux exigences de Greta et de sa directrice de la photographie, Sarah. Nous avons tourné dans mon appartement et essayé de nous débrouiller avec ce que nous avions, en utilisant également des practicals et tout ce que nous avons trouvé dans la maison. Le résultat n’est pas celui que nous nous étions fixé avec ambition, mais c’était quand même une belle expérience et cela a confirmé à quel point j’aime les lumières.",
      director: "Scénariste, Réalisatrice et Montatrice: ",
      casting: "Casting: ",
      photo: "Cinematographer: ",
      realizzatore: "Assistant Director: ",
      operatore: "Camera assistant: ",
      dirLuci: "Chief electrician: ",
      tecSuono: "Sound Operator: ",
      place: "Suède",
      role: "Chef électricien",
    },
  };
  return (
    <div className="project-page-wrapper">
      <SecondaryNavbar />
      <div className="project-page-video-left">
        <YouTube
          videoId="lc27XSYAtf0" // Replace with the YouTube video ID you want to display
          className="project-page-video" // Add a class name for styling if needed
        />
        <p className="project-video-description">
          {texts[language].description}
        </p>
      </div>
      <div className="project-page-right">
        <div className="right-items">
          <p className="upper-item">A&L</p>
          <p className="upper-item">2022</p>
          <p className="upper-item">{texts[language].role}</p>
          <p className="upper-item">{texts[language].place}</p>
        </div>
        <p className="half-list"></p>
        <div className="left-items">
          <p className="lower-item">
            <strong>{texts[language].director}</strong> Greta Fazzi
          </p>
          <p className="lower-item">
            <strong>{texts[language].casting}</strong> Achintya Nilsen, Daniele
            Suditaite, Gabriel King
          </p>
          <p className="lower-item">
            <strong>{texts[language].photo}</strong> Sarah Schäfer
          </p>
          <p className="lower-item">
            <strong>{texts[language].realizzatore}</strong> Mila Van Gelder
          </p>
          <p className="lower-item">
            <strong>{texts[language].operatore}</strong> Niklas Dopheide
          </p>

          <p className="lower-item">
            <strong>{texts[language].dirLuci}</strong>Marco Russo
          </p>

          <p className="lower-item">
            <strong>{texts[language].tecSuono}</strong>Caterina Fanti
          </p>
        </div>
      </div>
    </div>
  );
}
