import SecondaryNavbar from "../../components/SecondaryNavbar";
import { LanguageContext } from "../../LanguageContext";
import React, { useContext } from "react";
import "../ProjectPage/projectpage.css";
import YouTube from "react-youtube";

export default function MidnightCircus() {
  const { language } = useContext(LanguageContext);
  const texts = {
    ita: {
      description:
        "In questo progetto in bianco e nero mi sono occupato della camera e del focus. Era la mia prima volta, mi sono divertito un sacco. Ho trovato molto motivante cercare di prendere il focus perfettamente, calcolare la distanza dalla camera. Inoltre ho dovuto tenere tutta l’apparecchiatura in ordine ed aiutare il DoP come meglio potevo. Credo di aver fatto un discreto lavoro e la collaborazione con Curtis è ciò che mi ha spinto a chiedergli di girare il mio progetto di fine anno.",
      director: "Scrittore e Regista: ",
      casting: "Cast: ",
      photo: "Direttore della fotografia: ",
      montage: "Montaggio: ",
      dirAssist: "Assistente camera: ",
      dirLuci: "Capo elettricista: ",
      assistLuci: "Assistenti elettricista: ",

      tecSuono: "Tecnico del suono: ",

      place: "Direttore della fotografia",
      role: "Assistente camera",
    },
    eng: {
      description:
        "In this black and white project I took care of the camera and the focus. It was my first time, I had a lot of fun. I found it very motivating to try to take the focus perfectly, calculate the distance from the camera, etc. I also had to keep all the equipment in order and help the DoP as best I could. I think I’ve done a pretty good job, and this collaboration with Curtis is what made me ask him to shoot my exam project.",
      director: "Writer and Director: ",
      casting: "Cast: ",
      photo: "Director of photography: ",
      montage: "Editing: ",
      dirAssist: "Camera assistant: ",
      dirLuci: "Gaffer: ",
      assistLuci: "Best girl: ",
      tecSuono: "Sound operator: ",
      place: "Sweden",
      role: "Camera assistant",
    },
    fra: {
      description:
        "Dans ce projet en noir et blanc, je me suis occupé de la caméra et du focus. C’était ma première fois, j’ai beaucoup apprécié cela. J’ai trouvé très motivant d’essayer de prendre la concentration parfaitement, calculer la distance de la caméra, etc. J’ai également dû garder tout l’équipement en ordre et aider le dop du mieux que je pouvais. Je crois que j’ai fait un bon travail et la collaboration avec Curtis est ce qui m’a poussé à lui demander de tourner mon projet de fin d’année.",
      director: "Réalisateur et scénariste: ",
      casting: "Casting: ",
      photo: "Directeur de la photographie: ",
      montage: "Montage: ",
      dirAssist: "Assistant caméra: ",
      dirLuci: "Chef électricien: ",
      assistLuci: "Assistants électriciens: ",
      tecSuono: "Opérateur son et conducteur: ",
      place: "Suède",
      role: "Assistant caméra",
    },
  };
  return (
    <div className="project-page-wrapper">
      <SecondaryNavbar />
      <div className="project-page-video-left">
        <YouTube
          videoId="NiUUpWpavUc" // Replace with the YouTube video ID you want to display
          className="project-page-video" // Add a class name for styling if needed
        />
        <p className="project-video-description">
          {texts[language].description}
        </p>
      </div>
      <div className="project-page-right">
        <div className="right-items">
          <p className="upper-item">MIDNIGHT CIRCUS</p>
          <p className="upper-item">2023</p>
          <p className="upper-item">{texts[language].role}</p>
          <p className="upper-item">{texts[language].place}</p>
        </div>
        <p className="half-list"></p>
        <div className="left-items">
          <p className="lower-item">
            <strong>{texts[language].director}</strong> Mila Van Gelder
          </p>
          <p className="lower-item">
            <strong>{texts[language].casting}</strong> Anna Nolte, Julia
            Kollberg, Lucas Wallgren
          </p>
          <p className="lower-item">
            <strong>{texts[language].photo}</strong> Curtis Lee Terry
          </p>
          <p className="lower-item">
            <strong>{texts[language].montage}</strong> Mila Van Gelder
          </p>
          <p className="lower-item">
            <strong>{texts[language].dirAssist}</strong> Marco Russo
          </p>

          <p className="lower-item">
            <strong>{texts[language].dirLuci}</strong>Robin Yu Schmersel
          </p>
          <p className="lower-item">
            <strong>{texts[language].assistLuci}</strong>Polina Rubis
          </p>

          <p className="lower-item">
            <strong>{texts[language].tecSuono}</strong>Alessandro Zoppo
          </p>
        </div>
      </div>
    </div>
  );
}
