import SecondaryNavbar from "../../components/SecondaryNavbar";
import { LanguageContext } from "../../LanguageContext";
import React, { useContext } from "react";
import "../ProjectPage/projectpage.css";
import YouTube from "react-youtube";

export default function FoolForLove() {
  const { language } = useContext(LanguageContext);
  const texts = {
    ita: {
      description:
        "La mia prima vera esperienza come DoP. Per il progetto in Bianco e Nero sono stato scelto come direttore della fotografia, a supporto di Alessandro Zoppo, amico e coinquilino. Abbiamo lavorato assieme per riprodurre qualcosa che assomigliasse a un’esperienza che lui ha vissuto quando studiava recitazione in Italia. La sua storia molto personale non è stata facile da inquadrare in uno stile visivo specifico, ma ho dato il 100% per creare qualcosa di cui potessimo entrambi andare fieri. È stato un esercizio interessante e tutto sommato sono contento del mio operato dietro la camera, nonostante l’inesperienza. Ho scoperto che mi piace ragionare su inquadratura e luci in maniera meticolosa, lottando contro il tempo per produrre qualcosa di bello e funzionale alla storia allo stesso tempo. Alessandro è tutt’ora contento del risultato e questo mi riempie d’orgoglio.",
      director: "Scrittore e Regista: ",
      casting: "Cast: ",
      photo: "Direttore della fotografia: ",
      montage: "Montaggio: ",
      dirAssist: "Assistente alla regia: ",
      dirLuci: "Capo elettricista: ",
      assistLuci: "Assistenti elettricista: ",

      tecSuono: "Tecnico del suono: ",

      place: "Svezia",
      role: "Direttore della fotografia",
    },
    eng: {
      description:
        "My first real experience as a cinematographer. For the Black and White project I was chosen as director of photography, in support of Alessandro Zoppo, friend and roommate. We worked together to reproduce something that resembled an experience he had when he was studying acting in Italy. His very personal story was not easy to frame into a specific visual style, but I gave 100% to create something we could both be proud of. It was an interesting exercise and after all I am happy with my work behind the camera, despite my inexperience. I found that I like to think about framing and lighting meticulously, struggling against time to produce something beautiful and functional for the story at the same time. Alessandro is still happy with the result and this fills me with pride.",
      director: "Writer and Director: ",
      casting: "Cast: ",
      photo: "Director of photography: ",
      montage: "Editing: ",
      dirAssist: "Assistant director: ",
      dirLuci: "Gaffer: ",
      assistLuci: "Best boy: ",

      tecSuono: "Sound operator: ",

      place: "Sweden",
      role: "Cinematographer",
    },
    fra: {
      description:
        "Ma première vraie expérience en tant que cinématographe. Pour le projet Blanc et Noir, j’ai été choisi comme directeur de la photographie, en soutien à Alessandro Zoppo, ami et colocataire. Nous avons travaillé ensemble pour reproduire quelque chose qui ressemblait à une expérience qu’il a vécue à l’époque où il étudiait dans une école de théâtre en Italie. Son histoire très personnelle n’a pas été facile à cadrer dans un style visuel spécifique, mais j’ai donné 100% pour créer quelque chose dont nous pourrions tous les deux être fiers. C’était un exercice intéressant et après tout, je suis content de mon travail derrière la caméra, malgré mon inexpérience. J’ai découvert que j’aime bien réfléchir méticuleusement sur le cadrage et l’éclairage, en luttant contre le temps pour produire quelque chose de beau et fonctionnel pour l’histoire en même temps. Alessandro est toujours content du résultat et cela me rend fier.",
      director: "Réalisateur et scénariste: ",
      casting: "Casting: ",
      photo: "Directeur de la photographie: ",
      montage: "Montage: ",
      dirAssist: "Assistant réalisateur: ",
      dirLuci: "Chef électricien: ",
      assistLuci: "Assistants électriciens: ",

      tecSuono: "Opérateur son et conducteur: ",

      place: "Suède",
      role: "Directeur de la photographie",
    },
  };
  return (
    <div className="project-page-wrapper">
      <SecondaryNavbar />
      <div className="project-page-video-left">
        <YouTube
          videoId="jedpOrB_jfI" // Replace with the YouTube video ID you want to display
          className="project-page-video" // Add a class name for styling if needed
        />
        <p className="project-video-description">
          {texts[language].description}
        </p>
      </div>
      <div className="project-page-right">
        <div className="right-items">
          <p className="upper-item">FOOL FOR LOVE</p>
          <p className="upper-item">2023</p>
          <p className="upper-item">{texts[language].role}</p>
          <p className="upper-item">{texts[language].place}</p>
        </div>
        <p className="half-list"></p>
        <div className="left-items">
          <p className="lower-item">
            <strong>{texts[language].director}</strong> Alessandro Zoppo
          </p>
          <p className="lower-item">
            <strong>{texts[language].casting}</strong> Daniel Dorenius, Lucia
            Olivegren, Tanja Erkkilä
          </p>
          <p className="lower-item">
            <strong>{texts[language].photo}</strong> Marco Russo
          </p>
          <p className="lower-item">
            <strong>{texts[language].montage}</strong> Robin Yu Schmersel
          </p>
          <p className="lower-item">
            <strong>{texts[language].dirAssist}</strong> Curtis Lee Terry
          </p>

          <p className="lower-item">
            <strong>{texts[language].dirLuci}</strong>Polina Rubis
          </p>
          <p className="lower-item">
            <strong>{texts[language].assistLuci}</strong>Robin Yu Schmersel
          </p>

          <p className="lower-item">
            <strong>{texts[language].tecSuono}</strong>Mila Van Gelder
          </p>
        </div>
      </div>
    </div>
  );
}
