import React, { useContext, useState } from "react";
import Kyle from "../../images/kyledefinitivo.png";
import Shoes from "../../images/SHOES1.png";
import NTP1 from "../../images/NTP1.png";
import PFS1 from "../../images/PFS1-1.png";
import "./home.css";
import Navbar from "../../components/Navbar";
import { LanguageContext } from "../../LanguageContext";
import { Link } from "react-router-dom";
import GelataioAmico from "../../images/GelatoAmico.png";
import Atersaad from "../../images/atersadd.png";
import HerChoice from "../../images/HerChoice.png";
import Waste from "../../images/Waste.png";
import Ffl from "../../images/foolforlove.png";
import MidnightCircus from "../../images/Midnightcircus.png";
import AEL from "../../images/ael.png";
import QuantumAcademy from "../../images/QuantumAcademy.png";
import FacciaDaMorto from "../../images/facciadamorto.png";
import Rifiuti from "../../images/rifiuti.png";

const ImageWithText = ({ src, alt, text, category, selectedCategory }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`mosaic-image ${
        category === selectedCategory || selectedCategory === "" ? "" : "hidden"
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={src} alt={alt} className="gallery-image" />
      {isHovered && (
        <div className="text-overlay">
          <div className="centro">
            <p>{text.text1}</p>
            <p>{text.text2}</p>
            <p>{text.text3}</p>
            <p>{text.text6}</p>
            <p>{text.text4}</p>
            <p>{text.text5}</p>
          </div>
          {/*<p>{text}</p>*/}
        </div>
      )}
    </div>
  );
};

const CellulareWithText = ({ src, alt, text, category, selectedCategory }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`cellulare-image ${
        category === selectedCategory || selectedCategory === "" ? "" : "hidden"
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={src} alt={alt} className="gallery-image" />
      {isHovered && (
        <div className="text-overlay">
          <div className="centro">
            <p>{text.text1}</p>
            <p>{text.text2}</p>
            <p>{text.text3}</p>
            <p>{text.text6}</p>
            <p>{text.text4}</p>
            <p>{text.text5}</p>
          </div>
          {/*<p>{text}</p>*/}
        </div>
      )}
    </div>
  );
};

export default function Home() {
  const [category, setCategory] = useState("");

  const handleLinkClick = () => {
    // Posiziona la pagina all'inizio
    window.scrollTo(0, 0);
  };

  const handleCategorySelect = (selectedCategory) => {
    setCategory(selectedCategory);
  };
  const { language } = useContext(LanguageContext);

  const texts = {
    ita: {
      kyle: {
        text1: "KYLE TRAVEL",
        text2: "Volume 1",
        text3: "2023",
        text6: "Sceneggiatore",
        text4: "Dupuis",
        text5: "Belgio",
      },
      shoes: {
        text1: "SHOES",
        text2: "2022",
        text3: "Regia e Sceneggiatura",
        text4: "Stockholms Filmskola",
      },
      pfs1: {
        text1: "PARIS FASHION SLAM",
        text2: "2020",
        text3: "Sceneggiatura",
        text4: "Panini Comics",
        text5: "Italia",
      },
      ntp1: {
        text1: "NOTHING TO PROVE",
        text2: "2022",
        text3: "Regia e Sceneggiatura",
        text4: "Stockholms Filmskola",
        text5: "Svezia",
      },
      gelatoamico: {
        text1: "GELATO AMICO",
        text2: "2016",
        text3: "Sceneggiatore",
        text4: "Italia",
      },
      atersaad: {
        text1: "ÅTERSÅDD",
        text2: "2023",
        text3: "Assistente animatore",
        text4: "Svezia",
      },
      herchoice: {
        text1: "HER CHOICE",
        text2: "2023",
        text3: "Scrittore, Regista, Montatore",
        text4: "Stockholms Filmskola",
        text5: "Svezia",
      },
      waste: {
        text1: "WASTE",
        text2: "2023",
        text3: "Tecnico del suono",
        text4: "Stockholms Filmskola",
        text5: "Svezia",
      },
      ffl: {
        text1: "FOOL FOR LOVE",
        text2: "2023",
        text3: "Direttore della fotografia",
        text4: "Stockholms Filmskola",
        text5: "Svezia",
      },
      midnightcircus: {
        text1: "MIDNIGHT CIRCUS",
        text2: "2023",
        text3: "Assistente camera",
        text4: "Stockholms Filmskola",
        text5: "Svezia",
      },
      ael: {
        text1: "A&L",
        text2: "2022",
        text3: "Capo elettricista",
        text4: "Stockholms Filmskola",
        text5: "Svezia",
      },
      quantumacademy: {
        text1: "QUANTUM ACADEMY APOCRYPHA",
        text2: "2017",
        text3: "Sceneggiatore",
        text4: "Manfont",
        text5: "Italia",
      },
      facciadamorto: {
        text1: "FACCIA DA MORTO",
        text2: "2016",
        text3: "Sceneggiatore",
        text4: "Ora Pro Comics",
        text5: "Italia",
      },
      rifiuti: {
        text1: "RIFIUTI",
        text2: "2016",
        text3: "Sceneggiatore",
        text4: "Italia",
      },
    },
    eng: {
      kyle: {
        text1: "KYLE TRAVEL",
        text2: "Volume 1",
        text3: "2023",
        text6: "Writer",
        text4: "Dupuis",
        text5: "Belgium",
      },
      shoes: {
        text1: "SHOES",
        text2: "2022",
        text3: "Director and Writer",
        text4: "Stockholms Filmskola",
        text5: "Sweden",
      },
      pfs1: {
        text1: "PARIS FASHION SLAM",
        text2: "2020",
        text3: "Writer",
        text4: "Panini Comics",
        text5: "Italy",
      },
      ntp1: {
        text1: "NOTHING TO PROVE",
        text2: "2022",
        text3: "Writer and Director",
        text4: "Stockholms Filmskola",
        text5: "Sweden",
      },
      gelatoamico: {
        text1: "GELATO AMICO",
        text2: "2016",
        text3: "Writer",
        text4: "Italy",
      },
      atersaad: {
        text1: "ÅTERSÅDD",
        text2: "2023",
        text3: "Assistant animator",
        text4: "Sweden",
      },
      herchoice: {
        text1: "HER CHOICE",
        text2: "2023",
        text3: "Writer, Director, Editor",
        text4: "Stockholms Filmskola",
        text5: "Sweden",
      },
      waste: {
        text1: "WASTE",
        text2: "2023",
        text3: "Sound operator",
        text4: "Stockholms Filmskola",
        text5: "Sweden",
      },
      ffl: {
        text1: "FOOL FOR LOVE",
        text2: "2023",
        text3: "Cinematographer",
        text4: "Stockholms Filmskola",
        text5: "Sweden",
      },
      midnightcircus: {
        text1: "MIDNIGHT CIRCUS",
        text2: "2023",
        text3: "Camera assistant",
        text4: "Stockholms Filmskola",
        text5: "Sweden",
      },
      ael: {
        text1: "A&L",
        text2: "2022",
        text3: "Gaffer",
        text4: "Stockholms Filmskola",
        text5: "Sweden",
      },
      quantumacademy: {
        text1: "QUANTUM ACADEMY APOCRYPHA",
        text2: "2017",
        text3: "Writer",
        text4: "Manfont",
        text5: "Italy",
      },
      facciadamorto: {
        text1: "FACCIA DA MORTO",
        text2: "2016",
        text3: "Writer",
        text4: "Ora Pro Comics",
        text5: "Italy",
      },
      rifiuti: {
        text1: "RIFIUTI",
        text2: "2016",
        text3: "Writer",
        text4: "Italy",
      },
    },
    fra: {
      kyle: {
        text1: "KYLE TRAVEL",
        text3: "2023",
        text2: "Tome 1",
        text6: "Scénariste",
        text4: "Dupuis",
        text5: "Belgique",
      },
      shoes: {
        text1: "Shoes",
        text2: "2022",
        text3: "Réalisateur et Scénariste",
        text4: "Stockholms Filmskola",
      },
      pfs1: {
        text1: "PARIS FASHION SLAM",
        text2: "2020",
        text3: "Scénariste",
        text4: "Panini Comics",
        text5: "Italie",
      },
      ntp1: {
        text1: "NOTHING TO PROVE",
        text2: "2022",
        text3: "Scénariste et Réalisateur",
        text4: "Stockholms Filmskola",
        text5: "Suède",
      },
      gelatoamico: {
        text1: "GELATO AMICO",
        text2: "2016",
        text3: "Scénariste",
        text4: "Italie",
      },
      atersaad: {
        text1: "ÅTERSÅDD",
        text2: "2023",
        text3: "Assistant à l’animation",
        text4: "Suède",
      },
      herchoice: {
        text1: "HER CHOICE",
        text2: "2023",
        text3: "Scénariste, Réalisateur, Monteur",
        text4: "Stockholms Filmskola",
        text5: "Suède",
      },
      waste: {
        text1: "WASTE",
        text2: "2023",
        text3: "Opérateur son",
        text4: "Stockholms Filmskola",
        text5: "Suède",
      },
      ffl: {
        text1: "FOOL FOR LOVE",
        text2: "2023",
        text3: "Directeur de la photographie",
        text4: "Stockholms Filmskola",
        text5: "Suède",
      },
      midnightcircus: {
        text1: "MIDNIGHT CIRCUS",
        text2: "2023",
        text3: "Assistant caméra",
        text4: "Stockholms Filmskola",
        text5: "Suède",
      },
      ael: {
        text1: "A&L",
        text2: "2022",
        text3: "Chef électricien",
        text4: "Stockholms Filmskola",
        text5: "Suède",
      },
      quantumacademy: {
        text1: "QUANTUM ACADEMY APOCRYPHA",
        text2: "2017",
        text3: "Scénariste",
        text4: "Manfont",
        text5: "Italie",
      },
      facciadamorto: {
        text1: "FACCIA DA MORTO",
        text2: "2016",
        text3: "Scénariste",
        text4: "Ora Pro Comics",
        text5: "Italie",
      },
      rifiuti: {
        text1: "RIFIUTI",
        text2: "2016",
        text3: "Scénariste",
        text4: "Italie",
      },
    },
  };

  return (
    <div className="home-container">
      <Navbar onCategorySelect={handleCategorySelect} />
      <div className="mosaic">
        {/* INIZIO PRIMA COLONNA */}
        <Link to="kylepage" onClick={handleLinkClick}>
          <ImageWithText
            src={Kyle}
            alt="kylet"
            text={texts[language].kyle}
            category="comics"
            selectedCategory={category}
          />
        </Link>
        <Link to="waste" onClick={handleLinkClick}>
          <ImageWithText
            src={Waste}
            alt="waste"
            text={texts[language].waste}
            category="cinema"
            selectedCategory={category}
          />
        </Link>
        <Link to="ntp" onClick={handleLinkClick}>
          <ImageWithText
            src={NTP1}
            alt="ntp1"
            text={texts[language].ntp1}
            category="cinema"
            selectedCategory={category}
          />
        </Link>
        <Link to="projectpage" onClick={handleLinkClick}>
          <ImageWithText
            src={Shoes}
            alt="shoes"
            text={texts[language].shoes}
            category="cinema"
            selectedCategory={category}
          />
        </Link>
        <Link to="facciadamorto" onClick={handleLinkClick}>
          <ImageWithText
            src={FacciaDaMorto}
            alt="facciadamorto"
            text={texts[language].facciadamorto}
            category="comics"
            selectedCategory={category}
          />
        </Link>
        {/* INIZIO SECONDA COLONNA */}
        <Link to="atersaad" onClick={handleLinkClick}>
          <ImageWithText
            src={Atersaad}
            alt="atersaad"
            text={texts[language].atersaad}
            category="animation"
            selectedCategory={category}
          />
        </Link>
        <Link to="ffl" onClick={handleLinkClick}>
          <ImageWithText
            src={Ffl}
            alt="ffl"
            text={texts[language].ffl}
            category="cinema"
            selectedCategory={category}
          />
        </Link>
        <Link to="ael" onClick={handleLinkClick}>
          <ImageWithText
            src={AEL}
            alt="ael"
            text={texts[language].ael}
            category="cinema"
            selectedCategory={category}
          />
        </Link>
        <Link to="quantumacademy" onClick={handleLinkClick}>
          <ImageWithText
            src={QuantumAcademy}
            alt="quantumacademy"
            text={texts[language].quantumacademy}
            category="comics"
            selectedCategory={category}
          />
        </Link>
        <Link to="gelatoamico" onClick={handleLinkClick}>
          <ImageWithText
            src={GelataioAmico}
            alt="gelatoamico"
            text={texts[language].gelatoamico}
            category="advertising"
            selectedCategory={category}
          />
        </Link>
        {/* INIZIO TERZA COLONNA */}
        <Link to="herchoice" onClick={handleLinkClick}>
          <ImageWithText
            src={HerChoice}
            alt="herchoice"
            text={texts[language].herchoice}
            category="cinema"
            selectedCategory={category}
          />
        </Link>
        <Link to="midnightcircus" onClick={handleLinkClick}>
          <ImageWithText
            src={MidnightCircus}
            alt="midnightcircus"
            text={texts[language].midnightcircus}
            category="cinema"
            selectedCategory={category}
          />
        </Link>

        <Link to="PFSM" onClick={handleLinkClick}>
          <ImageWithText
            src={PFS1}
            alt="pfs1"
            text={texts[language].pfs1}
            category="comics"
            selectedCategory={category}
          />
        </Link>

        <Link to="rifiuti" onClick={handleLinkClick}>
          <ImageWithText
            src={Rifiuti}
            alt="rifiuti"
            text={texts[language].rifiuti}
            category="comics"
            selectedCategory={category}
          />
        </Link>
      </div>

      <div className="cellulare">
        {/* INIZIO PRIMA COLONNA */}
        <Link to="kylepage" onClick={handleLinkClick}>
          <CellulareWithText
            src={Kyle}
            alt="kylet"
            text={texts[language].kyle}
            category="comics"
            selectedCategory={category}
          />
        </Link>
        <Link to="waste" onClick={handleLinkClick}>
          <CellulareWithText
            src={Waste}
            alt="waste"
            text={texts[language].waste}
            category="cinema"
            selectedCategory={category}
          />
        </Link>

        <Link to="ntp" onClick={handleLinkClick}>
          <CellulareWithText
            src={NTP1}
            alt="ntp1"
            text={texts[language].ntp1}
            category="cinema"
            selectedCategory={category}
          />
        </Link>
        <Link to="projectpage" onClick={handleLinkClick}>
          <CellulareWithText
            src={Shoes}
            alt="shoes"
            text={texts[language].shoes}
            category="cinema"
            selectedCategory={category}
          />
        </Link>
        <Link to="ffl" onClick={handleLinkClick}>
          <CellulareWithText
            src={Ffl}
            alt="ffl"
            text={texts[language].ffl}
            category="cinema"
            selectedCategory={category}
          />
        </Link>
        <Link to="facciadamorto" onClick={handleLinkClick}>
          <CellulareWithText
            src={FacciaDaMorto}
            alt="facciadamorto"
            text={texts[language].facciadamorto}
            category="comics"
            selectedCategory={category}
          />
        </Link>
        {/* INIZIO SECONDA COLONNA */}

        <Link to="quantumacademy" onClick={handleLinkClick}>
          <CellulareWithText
            src={QuantumAcademy}
            alt="quantumacademy"
            text={texts[language].quantumacademy}
            category="comics"
            selectedCategory={category}
          />
        </Link>
        <Link to="atersaad" onClick={handleLinkClick}>
          <CellulareWithText
            src={Atersaad}
            alt="atersaad"
            text={texts[language].atersaad}
            category="animation"
            selectedCategory={category}
          />
        </Link>

        <Link to="herchoice" onClick={handleLinkClick}>
          <CellulareWithText
            src={HerChoice}
            alt="herchoice"
            text={texts[language].herchoice}
            category="cinema"
            selectedCategory={category}
          />
        </Link>

        <Link to="ael" onClick={handleLinkClick}>
          <CellulareWithText
            src={AEL}
            alt="ael"
            text={texts[language].ael}
            category="cinema"
            selectedCategory={category}
          />
        </Link>
        <Link to="midnightcircus" onClick={handleLinkClick}>
          <CellulareWithText
            src={MidnightCircus}
            alt="midnightcircus"
            text={texts[language].midnightcircus}
            category="cinema"
            selectedCategory={category}
          />
        </Link>
        <Link to="PFSM" onClick={handleLinkClick}>
          <CellulareWithText
            src={PFS1}
            alt="pfs1"
            text={texts[language].pfs1}
            category="comics"
            selectedCategory={category}
          />
        </Link>
        {/* INIZIO TERZA COLONNA */}
        <Link to="rifiuti" onClick={handleLinkClick}>
          <CellulareWithText
            src={Rifiuti}
            alt="rifiuti"
            text={texts[language].rifiuti}
            category="comics"
            selectedCategory={category}
          />
        </Link>
        <Link to="gelatoamico" onClick={handleLinkClick}>
          <CellulareWithText
            src={GelataioAmico}
            alt="gelatoamico"
            text={texts[language].gelatoamico}
            category="advertising"
            selectedCategory={category}
          />
        </Link>
      </div>
    </div>
  );
}
