import SecondaryNavbar from "../../components/SecondaryNavbar";
import { LanguageContext } from "../../LanguageContext";
import React, { useContext } from "react";
import YouTube from "react-youtube";
import "../ProjectPage/projectpage.css";

export default function HerChoice() {
  const { language } = useContext(LanguageContext);
  const texts = {
    ita: {
      description:
        "Il mio progetto di fine anno. Il mio script è di quelli selezionati nella mia classe, i quattro che sono stati prodotti. La scuola ci ha dato un piccolo budget, che abbiamo cercato di aumentare tramite una campagna Indiegogo che tutto sommati ci ha dato una piccola soddisfazione pur non avendo raggiunto l’obbiettivo. Sono molto fiero dell’idea alla base della storia, del dilemma tra i personaggi. Non sono tuttavia sicuro di essere riuscito a trasferirlo nel prodotto finale quanto avrei voluto. Abbiamo avuto molti problemi durante la pre-produzione, ma poi anche sul set e nella post- produzione. È stata un’esperienza faticosa, fisicamente e psicologicamente. E penso che l’editing non sia del tutto finito, proverò a lavorarci ancora un po’. E chissà, magari troverò la forza di tramutarlo in qualcosa di più, più lungo e con un’approfondimento maggiore dei personaggi. Ad ogni modo, a distanza di qualche mese, riesco a riguardarlo e provare un po’ di soddisfazione per essere riuscito in qualche modo a concluderlo.",
      director: "Scrittore, regista, montatore: ",
      casting: "Cast: ",
      photo: "Direttore della fotografia: ",
      montage: "Montaggio: ",
      dirAssist: "Assistente alla regia: ",
      camAssist: "Assistente camera: ",
      dirLuci: "Capo elettricista: ",
      assistLuci: "Assistenti elettricista: ",
      script: "Segretaria d’edizione: ",
      tecSuono: "Tecnico del suono: ",
      place: "Svezia",
      role: "Scrittore, Regista, Montatore",
    },
    eng: {
      description:
        "My exam project. My script is one of those selected in my class, the four that were produced. The school gave us a small budget, which we tried to increase through an Indiegogo campaign that honestly gave us a little satisfaction despite not having achieved the goal. I’m very proud of the idea behind the story, the dilemma between the characters. However, I’m not sure I could transfer it to the final product as much as I would have liked. We had a lot of problems during pre-production, but then also on set and in post-production. It was a though experience, physically and emotionally. And I think the editing is not quite finished, I’ll try to work on it a bit more. And who knows, maybe I’ll find the way to turn it into something more, longer and deeper into the characters psychology. Anyway, after a few months, I can look at it again and feel a bit of gratification for having managed in some way to make it.",
      director: "Writer, director, editor: ",
      casting: "Cast: ",
      photo: "Cinematographer: ",
      montage: "Editing: ",
      dirAssist: "Assistant director: ",
      camAssist: "Camera assistant: ",
      dirLuci: "Gaffer: ",
      assistLuci: "Best boy: ",
      script: "Script supervisor: ",
      tecSuono: "Sound operator: ",
      place: "Sweden",
      role: "Writer, Director, Editor",
    },
    fra: {
      description:
        "Mon projet de fin d’année. Mon script est l’un de ceux sélectionnés dans ma classe, les quatre qui ont été produits. L’école nous a donné un petit budget, que nous avons essayé d’augmenter grâce à une campagne Indiegogo qui nous a donné une petite satisfaction tout en n’ayant pas atteint l’objectif. Je suis très fier de l’idée derrière l’histoire, du dilemme entre les personnages. Je ne suis cependant pas aussi sûr d’avoir réussi à le transférer dans le produit final que je l’aurais souhaité. Nous avons eu beaucoup de problèmes pendant la pré-production, mais aussi sur le plateau et en post-production. Ce fut une expérience épuisante, physiquement et psychologiquement. Et je pense que le montage n’est pas tout à fait terminé, je vais essayer de travailler un peu plus dessus. Et peut-être que je trouverai la force de le transformer en quelque chose de plus, de plus long et de plus profond dans les personnages. Quoi qu’il en soit, après quelques mois, je peux le revoir et ressentir un peu de gratification d’avoir réussi à le terminer.",
      director: "Scénariste, réalisateur, monteur: ",
      casting: "Casting: ",
      photo: "Directeur de la photographie: ",
      montage: "Montage: ",
      dirAssist: "Assistant réalisateur: ",
      camAssist: "Assistant caméra: ",
      dirLuci: "Chef électricien: ",
      assistLuci: "Assistants électriciens: ",
      script: "Scripte: ",
      tecSuono: "Opératrice son: ",
      place: "Suède",
      role: "Scénariste, Réalisateur, Monteur",
    },
  };
  return (
    <div className="project-page-wrapper">
      <SecondaryNavbar />
      <div className="project-page-video-left">
        <YouTube
          videoId="YWrkh0bgCMY" // Replace with the YouTube video ID you want to display
          className="project-page-video" // Add a class name for styling if needed
        />
        <p className="project-video-description">
          {texts[language].description}
        </p>
      </div>
      <div className="project-page-right">
        <div className="right-items">
          <p className="upper-item">HER CHOICE</p>
          <p className="upper-item">2022</p>
          <p className="upper-item">{texts[language].role}</p>
          <p className="upper-item">{texts[language].place}</p>
        </div>
        <p className="half-list"></p>
        <div className="left-items">
          <p className="lower-item">
            <strong>{texts[language].director}</strong> Marco Russo
          </p>
          <p className="lower-item">
            <strong>{texts[language].casting}</strong> Matti Boustedt, Freja
            Edholm Borchies, Emmi Tjernström, Mark Albert Savickij, Lucy, Niklas
            Dopheide
          </p>
          <p className="lower-item">
            <strong>{texts[language].photo}</strong> Curtis Lee Terry
          </p>
          <p className="lower-item">
            <strong>{texts[language].montage}</strong> Emma Sederlin, Marco
            Russo
          </p>
          <p className="lower-item">
            <strong>{texts[language].dirAssist}</strong> Lucìa Diaz Losantos
          </p>
          <p className="lower-item">
            <strong>{texts[language].camAssist}</strong>Natacha Delatine
          </p>
          <p className="lower-item">
            <strong>{texts[language].dirLuci}</strong>Caterina Fanti
          </p>
          <p className="lower-item">
            <strong>{texts[language].assistLuci}</strong>Martino Passet
          </p>
          <p className="lower-item">
            <strong>Best Girl: </strong>Mila Van Gelder
          </p>

          <p className="lower-item">
            <strong>{texts[language].script}</strong>Daniella Strömgren
          </p>
          <p className="lower-item">
            <strong>{texts[language].tecSuono}</strong>Polina Rubis
          </p>
        </div>
      </div>
    </div>
  );
}
