import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LanguageProvider } from "./LanguageContext";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import ProjectPage from "./pages/ProjectPage/ProjectPage";
import KylePage from "./pages/KylePage/KylePage";
import PFSM from "./pages/PFSM/PFSM";
import NTP from "./pages/NTP/NTP";
import GelatoAmico from "./pages/GelatoAmico/GelatoAmico";
import Atersaad from "./pages/Atersaad/Atersaad";
import HerChoice from "./pages/HerChoice/HerChoice";
import Waste from "./pages/Waste/Waste";
import FoolForLove from "./pages/FFL/FoolForLove";
import MidnightCircus from "./pages/MidnightCircus/MidnightCircus";
import AEL from "./pages/AEL/AEL";
import QuantumAcademy from "./pages/QuantumAcademy/QuantumAcademy";
import FacciaDaMorto from "./pages/FacciaDaMorto/FacciaDaMorto";
import Rifiuti from "./pages/Rifiuti/Rifiuti";
const App = () => {
  return (
    <LanguageProvider>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/projectpage" element={<ProjectPage />} />
            <Route path="/kylepage" element={<KylePage />} />
            <Route path="/pfsm" element={<PFSM />} />
            <Route path="/ntp" element={<NTP />} />
            <Route path="/gelatoamico" element={<GelatoAmico />} />
            <Route path="/atersaad" element={<Atersaad />} />
            <Route path="/herchoice" element={<HerChoice />} />
            <Route path="/waste" element={<Waste />} />
            <Route path="/ffl" element={<FoolForLove />} />
            <Route path="/midnightcircus" element={<MidnightCircus />} />
            <Route path="/ael" element={<AEL />} />
            <Route path="/quantumacademy" element={<QuantumAcademy />} />
            <Route path="/facciadamorto" element={<FacciaDaMorto />} />
            <Route path="/rifiuti" element={<Rifiuti />} />

            {/* Aggiungi altre rotte per le tue pagine */}
          </Routes>
        </div>
      </Router>
    </LanguageProvider>
  );
};

export default App;
