import React, { useContext } from "react";
import { LanguageContext } from "../../LanguageContext";
import "./about.css";
import SecondaryNavbar from "../../components/SecondaryNavbar";
import Ritratto from "../../images/aboutritratto.png";
import Firma from "../../images/aboutfirma.png";

export default function About() {
  const { language } = useContext(LanguageContext);

  const texts = {
    ita: {
      text1: "Ciao !",
      text2:
        "Sono Marco, e voglio raccontare storie. Non importa con quale medium, tecnica o genere, comunicare qualcosa tramite una storia per me è la cosa più importante.",
      text3:
        "Lavoro principalmente come sceneggiatore, soprattutto di fumetti, per Francia, Belgio e Italia. Sto però ampliando i miei orizzonti nel cinema, come sceneggiatore e regista ma ho anche avuto esperienze come cinematographer, assistente camera, gaffer e tecnico del suono.",
      text4:
        "Attualmente di stanza a Stoccolma (Svezia), sono aperto a ogni forma di collaborazione creativa, a viaggiare per l’Europa e il mondo inseguendo storie e avventure da raccontare tramite fumetti, cortometraggi, documentari e pubblicità.",
      text5:
        "Grazie per aver visitato il mio sito, sentiti libero di contattarmi per discutere di progetti e collaborazioni.",
    },
    eng: {
      text1: "Hi !",
      text2:
        "I’m Marco, and I want to tell stories. No matter what medium, technique or genre, communicating something through a story is the most important thing to me.",
      text3:
        "I mainly work as a writer, especially in comics, for France, Belgium and Italy. But I am expanding my horizons in cinema, as a screenwriter and director, but I have also had experiences as a cinematographer, camera assistant, gaffer and sound operator.",
      text4:
        "Currently living in Stockholm (Sweden), I am open to any form of creative collaboration, to travel around Europe and the world chasing stories and adventures to tell through comics, short films, documentaries and advertising.",
      text5:
        "Thank you for visiting my website, feel free to contact me to discuss projects and collaborations.",
    },
    fra: {
      text1: "Salut !",
      text2:
        "Je suis Marco, et je veux raconter des histoires. Peu importe avec quel médium, technique ou genre, communiquer quelque chose par une histoire est pour moi la chose la plus importante.",
      text3:
        "Je travaille principalement comme scénariste, principalement de bandes dessinées, pour la France, la Belgique et l’Italie. Je suis en train d’élargir mes horizons dans le cinéma en tant que scénariste et réalisateur, mais j’ai aussi eu des expériences en tant que directeur de la photographie, assistant caméra, gaffer et operateur du son.",
      text4:
        "Actuellement basé à Stockholm (Suède), je suis ouvert à toute forme de collaboration créative, à voyager dans l’Europe et le monde à la recherche d’histoires et d’aventures à raconter à travers des bandes dessinées, des courts métrages, des documentaires et des publicités.",
      text5:
        "Merci de visiter mon site, n’hésitez pas à me contacter pour discuter de projets et de collaborations.",
    },
  };

  return (
    <div>
      <div className="about-page">
        <SecondaryNavbar />
        <div className="paragraph-div">
          <p className="about-paragraph">{texts[language].text1}</p>
          <p className="about-paragraph">{texts[language].text2}</p>
          <p className="about-paragraph">{texts[language].text3}</p>
          <p className="about-paragraph">{texts[language].text4}</p>
          <p className="about-paragraph">{texts[language].text5}</p>
          <div className="about-images">
            <img src={Ritratto} className="about-image" alt="ritratto" />
            <img src={Firma} className="about-image" alt="firma" />
          </div>
        </div>
      </div>
    </div>
  );
}
